import { setMessageModal } from "app/slices/messageModalSlice";
import { RootState } from "app/store";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Modal.css";

function MessageModal() {
  const dispatch = useDispatch();
  const global = useSelector((state: RootState) => state.messageModal);
  const isCompleted = global.isCompleted;
  const message = global.message;

  const resetAll = () => {
    window.location.assign(`/`);
  };

  const autoReset = useCallback(() => {
    setTimeout(() => {
      resetAll();
    }, 5000);
  }, []);

  useEffect(() => {
    if (isCompleted) {
      autoReset();
    }
  }, [autoReset, isCompleted]);

  return (
    <div className="absolute" style={{ left: "50%" }}>
      <div
        className="clickElseWhere fixCenter"
        onClick={() => dispatch(setMessageModal(""))}
      />
      <section className="modal py-3 z4">
        <div className="titleText my-4" style={{ fontSize: "24px" }}>
          {message}
        </div>
        <div className="flex-column-center full-width">
          <button
            className="my-1 full-width cancelButton"
            onClick={() => {
              dispatch(setMessageModal(""));
              isCompleted && resetAll();
            }}
            style={{ fontSize: "24px", height: "48px" }}
          >
            CLOSE
          </button>
        </div>
      </section>
    </div>
  );
}

export default MessageModal;
