import { Timestamp } from "firebase/firestore";

export const formatDate = (date: number | string) => {
  return new Date(date).toLocaleDateString("en-CA");
};

export const fromFirebaseTime = (date: Timestamp) => {
  return date.toDate();
};

export const toFirebaseTime = (date: Date) => {
  return Timestamp.fromDate(date);
};
export const today0000 = () => {
  return new Date(new Date().toLocaleDateString()).valueOf();
};

export const fromDB = (data: any) => {
  const result = {};
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === `undefined` || !value) {
      Object.assign(result, {
        [key]: value,
      });
    } else if (value instanceof Timestamp) {
      Object.assign(result, {
        [key]: value.toDate(),
      });
    } else if (typeof value === `object`) {
      if (Array.isArray(value)) {
        const newValue: any[] = [];
        value.forEach((item) => {
          newValue.push(fromDB(item));
        });
        Object.assign(result, {
          [key]: newValue,
        });
      } else {
        Object.assign(result, {
          [key]: fromDB(value),
        });
      }
    } else {
      Object.assign(result, {
        [key]: value,
      });
    }
  }
  return result;
};

export const toDB = (data: any) => {
  const result = {};
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === `undefined` || !value) {
      Object.assign(result, {
        [key]: value,
      });
    } else if (value instanceof Date) {
      Object.assign(result, {
        [key]: Timestamp.fromDate(value),
      });
    } else if (typeof value === `object`) {
      if (Array.isArray(value)) {
        const newValue: any[] = [];
        value.forEach((item) => {
          newValue.push(toDB(item));
        });
        Object.assign(result, {
          [key]: newValue,
        });
      } else {
        Object.assign(result, {
          [key]: toDB(value),
        });
      }
    } else {
      Object.assign(result, {
        [key]: value,
      });
    }
  }
  return result;
};

export const minToMil = (minutes: number) => {
  return minutes * 60 * 1000;
};
export const latestBeforeEnds = minToMil(5); //5 minutes
