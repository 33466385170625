import { InputField } from "App";
import { getSessionByNumber } from "app/firebase";
import { setIsCompleted } from "app/slices/messageModalSlice";
import { resetUsersState, setCheckInUser } from "app/slices/usersSlice";
import { RootState } from "app/store";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Modal.css";

function SessionNumberModal() {
  const [sessionNumber, setSessionNumber] = useState("");
  const dispatch = useDispatch();
  const session = useSelector((state: RootState) => state.session.session);

  const handleSubmit = async () => {
    await getSessionByNumber(sessionNumber);
  };

  useEffect(() => {
    if (!!session) {
      setSessionNumber("");
    }
  }, [session]);

  useEffect(() => {
    setSessionNumber("");
    return () => {
      setSessionNumber("");
    };
  }, []);

  return (
    <div className="modalContainer">
      <div
        className="clickElseWhere z1 fixCenter"
        style={{ background: "#0009" }}
        onClick={() => dispatch(setCheckInUser(null))}
      />
      <section className="sessionNumberModal py-3 z4" style={{ width: "80vw" }}>
        <div className="mb-2 mt-1" style={{ fontSize: "24px", color: "#222" }}>
          Please input session number
        </div>

        <div
          className="flex-column-ss waiverContainer"
          style={{
            color: "#fff",
            fontSize: "18px",
            maxHeight: "300px",
            overflowY: "auto",
            textAlign: "left",
          }}
        >
          <InputField
            type="tel"
            name={"Session number"}
            value={sessionNumber}
            onChange={(e) => setSessionNumber(e)}
          />
        </div>
        <div className="flex-center full-width mt-2">
          <button
            className="my-1 full-width mr-2"
            onClick={handleSubmit}
            style={{ fontSize: "24px", height: "48px", fontWeight: 500 }}
          >
            SUBMIT
          </button>
          <button
            className="my-1 full-width cancelButton"
            onClick={() => {
              dispatch(resetUsersState());
              dispatch(setIsCompleted(true));
              setSessionNumber("");
            }}
            style={{ fontSize: "24px", height: "48px", fontWeight: 500 }}
          >
            CANCEL
          </button>
        </div>
      </section>
    </div>
  );
}

export default SessionNumberModal;
