import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Session } from "@tsanghoilun/snow-n-surf-interface/types/session";

interface SessionState {
  session: Session | null;
}

const initialState: SessionState = {
  session: null,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSession: (
      state: SessionState,
      action: PayloadAction<Session | null>
    ) => {
      state.session = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSession } = sessionSlice.actions;

export default sessionSlice.reducer;
