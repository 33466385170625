import { RootState } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Customer } from "@tsanghoilun/snow-n-surf-interface/types/user";

interface UsersState {
  users: Customer[];
  checkInUser: Customer | null;
  missingDataUser: Customer | null;
  checkInUserArray: Customer[];
}

const initialState: UsersState = {
  users: [],
  checkInUser: null,
  missingDataUser: null,
  checkInUserArray: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state: UsersState, action: PayloadAction<Customer[]>) => {
      state.users = action.payload;
    },
    setCheckInUser: (
      state: UsersState,
      action: PayloadAction<Customer | null>
    ) => {
      state.checkInUser = action.payload;
    },
    setCheckInUserArray: (
      state: UsersState,
      action: PayloadAction<Customer[]>
    ) => {
      state.checkInUserArray = action.payload;
    },
    resetCheckInUserArray: (state: UsersState) => {
      state.checkInUserArray = [];
    },
    setMissingDataUser: (
      state: UsersState,
      action: PayloadAction<Customer | null>
    ) => {
      state.missingDataUser = action.payload;
    },
    resetUsersState: (state: UsersState) => {
      state.users = initialState.users;
      state.checkInUser = initialState.checkInUser;
      state.missingDataUser = initialState.missingDataUser;
      state.checkInUserArray = initialState.checkInUserArray;
    },
  },
});

// Selectors goes here
export const selectUsers = (state: RootState) => {
  return state.users.users;
};

// Action creators are generated for each case reducer function
export const {
  setUsers,
  setCheckInUser,
  setMissingDataUser,
  resetUsersState,
  setCheckInUserArray,
  resetCheckInUserArray,
} = usersSlice.actions;

export default usersSlice.reducer;
