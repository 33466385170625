import { addUserToSession, postWaiverRecord } from "app/firebase";
import { setIsCompleted, setIsLoading, setMessageModal } from "app/slices/messageModalSlice";
import { setSession } from "app/slices/sessionSlice";
import { setCheckInUser } from "app/slices/usersSlice";
import { RootState } from "app/store";
import html2canvas from "html2canvas";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { default as ReactSignatureCanvas, default as SignaturePad } from "react-signature-canvas";
import { Waiver } from "Waiver";
import Resizer from "react-image-file-resizer";
import "./Modal.css";
import { InputField } from "App";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import dayjs from "dayjs";

const canvasWidth = window.innerWidth * 0.9;

interface WaiverModalProps {
	onClose: () => void;
}

function WaiverModal(props: WaiverModalProps) {
	const [showSignedBy, setShowSignedBy] = useState(false);
	const [signedBy, setSignedBy] = useState("");
	const [imageString, setImageString] = useState("");
	const [signatureFile, setSignatureFile] = useState<File | null>(null);
	const dispatch = useDispatch();
	const signatureRef = useRef<ReactSignatureCanvas>(null);
	const waiverContainerRef = useRef<HTMLDivElement>(null);
	const checkInUser = useSelector((state: RootState) => state.users.checkInUser);
	const [checked, setChecked] = useState(false);

	const handleCheckIn = async () => {
		if (!signatureFile) return;
		dispatch(setIsLoading(true));
		await compressImage(signatureFile).then(async (compressedFile) => {
			try {
				await postWaiverRecord(compressedFile as File).then(async (waiverObject) => {
					setSignatureFile(null);
					setImageString("");
					if (waiverObject) {
						await addUserToSession(waiverObject).then((e) => {
							if (e === "success") {
								dispatch(setCheckInUser(null));
								dispatch(setSession(null));
								dispatch(
									setMessageModal(
										"Thank you! Please wait for a moment, the receptionist will check you in."
									)
								);
								dispatch(setIsCompleted(true));
							}
						});
					}
				});
				props.onClose();
			} catch (error) {
				console.error(error);
			} finally {
				dispatch(setIsLoading(false));
			}
		});
	};

	const handleClearSignature = () => {
		setImageString("");
		signatureRef.current?.clear();
		setSignatureFile(null);
	};

	const getWaiverImage = async () => {
		if (!checkInUser) return;
		if (waiverContainerRef.current) {
			html2canvas(waiverContainerRef.current).then(async (canvas) => {
				const waiver = canvas.toDataURL("image/png");
				const base64 = await fetch(waiver);
				const blob = await base64.blob();
				const file = new File([blob], checkInUser.realName, {
					type: "image/png",
				});
				setSignatureFile(file);
			});
		}
	};

	const handleDecline = () => {
		setImageString("");
		dispatch(setCheckInUser(null));
		props.onClose();
	};

	const checkUserAlreadyWavied = useCallback(async () => {
		if (!checkInUser) {
			return false;
		}

		const db = getFirestore();
		const q = query(
			collection(db, `waiverRecords`),
			where(`date`, ">=", dayjs.tz(dayjs()).startOf("day").subtract(3, "months").toDate()),
			where(`userId`, "==", checkInUser.id)
		);
		const docs = await getDocs(q);
		return !docs.empty;
	}, [checkInUser]);

	// add logic to check if user has signed wavier the past 3 months, or get wavier image
	useEffect(() => {
		const checkAndTreat = async () => {
			const wavied = await checkUserAlreadyWavied();
			if (wavied) {
				try {
					await addUserToSession().then((e) => {
						if (e === "success") {
							dispatch(setCheckInUser(null));
							dispatch(setSession(null));
							dispatch(
								setMessageModal(
									"Thank you! Please wait for a moment, the receptionist will check you in."
								)
							);
							dispatch(setIsCompleted(true));
						}
					});
					props.onClose();
				} catch (error) {
					console.error(error);
				}
			} else {
				getWaiverImage();
			}
      setChecked(true);
		};
		if (!checkInUser) {
      setChecked(false);
			return;
		}
		checkAndTreat();
	}, [imageString, checkInUser]);

	const compressImage = (file: File) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				4000,
				4000,
				"JPEG",
				35,
				0,
				(uri) => {
					resolve(uri);
				},
				"file",
				650
			);
		});

	useEffect(() => {
		if (checkInUser?.isSub) {
			setShowSignedBy(true);
		}
	}, [checkInUser?.isSub]);

	return (
		<>
			<div className={`modalContainer`}>
				<div
					className="clickElseWhere z1 fixCenter"
					style={{ background: "#0009" }}
					onClick={() => dispatch(setCheckInUser(null))}
				/>
				<section className={`waiverModal py-3 z4 clickElseWhere z1 fixCenter ${!checked && 'hidden'}`}>
					<div ref={waiverContainerRef} className="px-2 py-1 flex-column-center">
						<div className="mb-2 mt-1" style={{ fontSize: "20px", color: "#222" }}>
							Participation Agreement
						</div>

						<div className="flex-column-ss waiverContainer">
							<Waiver name={checkInUser?.realName!} />
						</div>
						<div
							className="relative mt-2 mb-1"
							style={{
								width: "90vw",
								border: "solid 1px #222",
								borderRadius: "8px",
							}}
						>
							<div
								className="absolute flex-row-between full-width px-1 borderBox"
								style={{ left: 0, top: 8, color: "#222" }}
							>
								<div>{!!signedBy ? `Signed by ${signedBy}` : "Signed"}</div>
								<div>{new Date().toLocaleDateString("en-CA")}</div>
							</div>

							<SignaturePad
								ref={signatureRef}
								penColor="#888"
								minWidth={0.5}
								maxWidth={2}
								onEnd={() => setImageString(signatureRef.current?.toDataURL("image/png")!)}
								canvasProps={{
									width: canvasWidth,
									height: (canvasWidth * 9) / 16,
								}}
							/>
						</div>
					</div>

					<div className="flex-column-center full-width mt-2">
						<button
							className="my-1 full-width"
							onClick={handleClearSignature}
							style={{ fontSize: "24px", height: "48px", fontWeight: 500 }}
						>
							CLEAR
						</button>
						<div className="full-width flex-center">
							<button
								disabled={!imageString}
								className="my-1 full-width mr-2"
								onClick={handleCheckIn}
								style={{ fontSize: "24px", height: "48px", fontWeight: 500 }}
							>
								ACCEPT
							</button>
							<button
								className="my-1 full-width cancelButton"
								onClick={handleDecline}
								style={{ fontSize: "24px", height: "48px", fontWeight: 500 }}
							>
								DECLINE
							</button>
						</div>
					</div>
				</section>
				{checkInUser && checkInUser.isSub && showSignedBy && (
					<>
						<div className="modalContainer">
							<div
								className="clickElseWhere z1 fixCenter z4"
								style={{ background: "#0009" }}
								onClick={() => dispatch(setCheckInUser(null))}
							/>
							<div className="waiverModal z4" style={{ height: "fit-content" }}>
								Who will be signing the waiver?
								<InputField
									placeholder={"Name as in HKID (You must be at least 18 years old)"}
									name={"Signed By"}
									value={signedBy}
									onChange={(e) => setSignedBy(e)}
								/>
								<button
									disabled={!signedBy}
									onClick={() => {
										setShowSignedBy(false);
									}}
								>
									SUBMIT
								</button>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default WaiverModal;
