import { configureStore } from "@reduxjs/toolkit";
// import authUserReducer from './slices/authUserSlice'
import usersReducer from "./slices/usersSlice";
import staffReducer from "./slices/staffSlice";
import messageModalReducer from "./slices/messageModalSlice";
import sessionReducer from "./slices/sessionSlice";
import authSlice from "./slices/authSlice";

export const store = configureStore({
  reducer: {
    authUser: authSlice,
    users: usersReducer,
    staff: staffReducer,
    messageModal: messageModalReducer,
    session: sessionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
