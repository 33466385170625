import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type RoleType = "staff" | "admin" | null;

interface StaffState {
  staff: {
    email: string;
    role: RoleType;
  };
  token: { time: number; staffId: string };
}

const initialState: StaffState = {
  staff: {
    email: "",
    role: null,
  },
  token: { time: 0, staffId: "" },
};

export const staffSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setStaff: (
      state: StaffState,
      action: PayloadAction<{ email: string; role: RoleType }>
    ) => {
      state.staff = action.payload;
    },
    setToken: () => {
      // localStorage.setItem("snfToken", JSON.stringify())
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStaff } = staffSlice.actions;

export default staffSlice.reducer;
