interface WaiverProps {
  name: string;
}
export const Waiver = (props: WaiverProps) => {
  return (
    <div className={"waiverText"}>
      <p>
        {`I, ${props.name} hereby, upon signing this agreement, agree to give up my
      rights and the rights of my spouse and/or child(ren) to bring any form of
      legal proceeding against Snow & Surf Limited for any injury, including
      paralysis, or death, caused in whole or in part by the negligence or fault
      of Snow & Surf Limited including any of its agents, employees, property
      and equipment.`}
      </p>
      <p>
        {`In consideration of being allowed to participate in the
      services and activities, including, but not limited to, for snow sports
      activity and/or surf activity access, use and access of its machines and
      facilities within its property and vending machine access and any other
      amusement activities (collectively “Activities”), provided by Snow and
      Surf Limited and its agents, owners, officers, directors, principals,
      volunteers, participants, clients, customers, invitees, employees,
      independent contractors, insurers, facility operators, land and/or
      premises owners, and any and all other persons and entities acting in any
      capacity on its behalf (collectively “SNOW & SURF”),I, on behalf of
      myself, and/or on behalf of my spouse, minor child(ren)/ward(s),hereby
      agree to forever release, indemnify and discharge SNOW & SURF on behalf of
      myself, my spouse, legal partner, my children, my parents, my guardians,
      heirs, assigns, personal representatives and estate, and all other persons
      and entities as set forth below. The undersigned, for myself, and/or on
      behalf of my spouse, minor child(ren)/ward(s), hereby acknowledges, agrees
      and represents that immediately upon entering or participating I will,
      inspect and carefully consider SNOW & SURF premises and facilities. It is
      further warranted that such entry into SNOW & SURF facilities for
      observation or use of any facilities or equipment or participation in
      Activities constitutes an acknowledgement that such premises and all
      facilities and equipment thereon have been inspected and carefully
      considered and that the undersigned finds and accepts same for myself,
      and/or on behalf of my spouse, minor child(ren)/ward(s) as being safe and
      reasonably suited for the purpose of such observation, use or
      participation by myself, and/or by my spouse, minor child(ren)/ward(s).
      The undersigned, for myself, and/or on behalf of my spouse, minor
      child(ren)/ward(s)hereby represent that (i) I/we are in good health and in
      proper physical condition to participate in the Activities in which SNOW &
      SURF provides; and (ii) I/we are not under the influence of alcohol or any
      illicit or prescription drugs which would in any way impair my/our ability
      to safely participate in Activities; (iii) I/we have not been advised
      against participation in any of the Activities by a health professional. I
      agree that it is my sole responsibility to determine whether I/we are
      sufficiently fit and healthy enough to participate in Activities. The
      undersigned, for myself, and/or on behalf of my spouse, minor
      child(ren)/ward(s), agree to be familiar with and to abide by the rules
      established for the Activities, which include without limitation the rules
      posted in the facility and/or the SNOW& SURF website. The undersigned, for
      myself, and/or on behalf of my spouse, minor child(ren)/ward(s), accepts
      sole responsibility for my own conduct and actions, as well as the conduct
      and actions of my spouse, minor child(ren)/ward(s) while participating in
      the Activities, and the condition and adequacy of the equipment.`}
      </p>
      <p>
        <strong>{"(1) RELEASE OF LIABILITY: "}</strong>
        {`Despite all known and unknown risks including but not
      limited to serious bodily injury, permanent disability, paralysis and loss
      of life, I, on behalf of myself, and/or on behalf of my spouse, minor
      child(ren)/ ward(s) hereby expressly and voluntarily remise, release,
      acquit, satisfy and forever discharge and agree not to sue SNOW & SURF,
      including its suppliers, designers, installers, manufacturers of any
      equipment, or such other material and equipment in SNOW & SURF facility
      (all herein after referred to as “EQUIPMENT SUPPLIERS”) and agree to hold
      said parties harmless of and from any and all manner of actions or
      omission(s), causes of action, suits, sums of money, controversies,
      damages, judgments, executions, claims and demands whatsoever, in law or
      in equity, including, but not limited to, any and all claims which allege
      negligent acts and/or omissions committed by SNOW & SURF and/or any
      EQUIPMENT SUPPLIERS, whether the action arises out of any damage, loss,
      tort, personal injury, or death to me or my spouse, minor
      child(ren)/ward(s), while participating in or as a result of participating
      in any of the Activities in or about the premises. This Release of
      Liability, is effective and valid regardless of whether the damage, loss,
      personal injury or death is a result of any act or omission on the part of
      SNOW & SURF and/or any EQUIPMENT SUPPLIERS.`}
      </p>
      <p>
        <strong>{"(2) INDEMNIFICATION: "}</strong>
        {`I understand
      that the known and unknown risks may be caused directly or indirectly, in
      whole or in part by my or my spouse or child(ren)/wards own actions or
      inactions, the actions or inactions or negligence of others participating
      in the Activities, or the acts, inaction or negligence of SNOW & SURF or
      any EQUIPMENT SUPPLIERS, and in consideration of being allowed, along with
      my spouse and/or my minor child(ren)/ward(s) to participate in the
      Activities, I hereby assume all risk of damage, loss, personal injury, or
      death to myself, my spouse and/or my minor child(ren)/ward(s) as a result
      of the participation in Activities in or about the facility, including any
      such loss due to any negligence (including gross negligence) of SNOW &
      SURF and all EQUIPMENT SUPPLIERS and agree to indemnify and hold harmless
      SNOW & SURF and all EQUIPMENT SUPPLIERS from and against any and all
      direct/indirect losses, liabilities, claims, obligations, costs, damages
      and/or expenses whatsoever paid, incurred and/or suffered by SNOW & SURF
      and all EQUIPMENT SUPPLIERS as a result of any claims asserted by myself,
      my spouse and/or child(ren)/ward(s) against SNOW & SURF and all EQUIPMENT
      SUPPLIERS, including, but not limited to, any and all attorneys’ fees,
      costs, damages and/or judgments SNOW & SURF and all EQUIPMENT SUPPLIERS
      incurs in the event of such loss whether caused by the negligence of SNOW
      & SURF or any EQUIPMENT SUPPLIERS and that on behalf of myself, my spouse
      or my minor child(ren)/ward(s) I further agree to indemnify and hold
      harmless SNOW & SURF for any injury, damage and/or harm myself, my spouse
      and/or my minor child(ren)/ward(s) cause to SNOW & SURF or its facility
      and/or to any and all other persons and entities acting in any capacity on
      behalf of SNOW & SURF.`}
      </p>
      <p>
        <strong>{"(3) ATTORNEYS’ FEES: "}</strong>
        {`I agree to indemnify SNOW & SURF
      for any attorneys’ fees and/or costs incurred to enforce this agreement,
      including all costs associated with any collection efforts. Further,
      should any debt and/or judgment accrue in favor of SNOW & SURF,
      pre-judgment and post judgment interest shall accrue thereon at a rate of
      18% per annum.`}
      </p>
      <p>
        <strong>{"(4) PHOTO RELEASE: "}</strong>
        {`By entering SNOW & SURF and participating in
      the Activities, I hereby grant SNOW & SURF on behalf of myself, my spouse
      and on behalf of my child(ren)/ward(s), the irrevocable right and
      permission to photograph and/or record me, my spouse or my child(ren)/
      ward(s) in connection with SNOW & SURF and to use the photograph and/or
      recording for all purposes, including advertising and promotional
      purposes, in any manner and all media now or hereafter known, in
      perpetuity throughout the world, without restriction as to alteration. I
      waive any right to inspect or approve the use of the photograph and/or
      recording, and acknowledge and agree that the rights granted to this
      release are without compensation of any kind.`}
      </p>
      <p>
        <strong>{`(5) TERMS OF AGREEMENT: `}</strong>
        {`I understand that this agreement shall continue to be legally enforceable
      unless expressly terminated by SNOW & SURF and will have full force and
      legal effect each and every time I or my spouse and/ or child(ren)/ward(s)
      visit SNOW & SURF, whether at the current location or any other location
      or facility. The undersigned further expressly agrees that this agreement
      is intended to be as broad and inclusive as is permitted by the applicable
      laws in Hong Kong SAR and that if any portion thereof is held invalid, it
      is agreed that the balance shall, notwithstanding, continue in full legal
      force and effect.`}
      </p>
      <p>
        <strong>{`(6) VENUE: `}</strong>
        {`In the event a legal proceeding is led against
      SNOW & SURF I agree to the sole and exclusive venue of SNOW & SURF. I
      further agree that the substantive law of Hong Kong SAR shall apply
      without regard to any conflict of law rules. By signing this document, I
      understand that I may be found by a court of law to have forever waived my
      and my spouse and/or child(ren)/ward(s)right to maintain any action
      against SNOW & SURF on the basis of any claim from which I have released
      SNOW & SURF and any released party herein and that I have assumed all risk
      of damage, loss, personal injury, or death to myself, my spouse and/or my
      minor child(ren)/ward(s) and agreed to indemnify and hold harmless SNOW &
      SURF and all EQUIPMENT SUPPLIERS from and against any and all direct or
      indirect losses, liabilities, claims, obligations, costs, damages and/or
      expenses whatsoever paid, incurred and/or suffered by SNOW & SURF and all
      EQUIPMENT SUPPLIERS as a result of the participation in the Activities in
      or about the facility by myself, my spouse and/or child(ren)/ward(s)
      and/or claims asserted by myself, my spouse and/ or child(ren)/ward(s)
      against SNOW & SURF and all EQUIPMENT SUPPLIERS related to such
      participation in Activities. I have had a reasonable and sufficient
      opportunity to read and understand this entire document and consult with
      legal counsel, or have voluntarily waived my right to do so. I knowingly
      and voluntarily agree to be bound by all terms and conditions set forth
      herein. `}
      </p>
    </div>
  );
};
