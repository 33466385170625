import { InputField } from "App";
import { updateUserRealInfo } from "app/firebase";
import { setIsCompleted, setIsLoading } from "app/slices/messageModalSlice";
import { setCheckInUser, setMissingDataUser } from "app/slices/usersSlice";
import { RootState } from "app/store";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Webcam from "react-webcam";
import "./Modal.css";

function MissingDataModal() {
  const missingDataUser = useSelector(
    (state: RootState) => state.users.missingDataUser
  );
  const [isNextStep, setIsNextStep] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [imageData, setImageData] = useState(missingDataUser?.realImage || "");
  const [realName, setRealName] = useState(missingDataUser?.realName || "");
  const webcamRef = useRef<Webcam>(null);
  const isCompleted = useSelector(
    (state: RootState) => state.messageModal.isCompleted
  );
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    dispatch(setIsLoading(true));
    await updateUserRealInfo(realName, file).then((e) => {
      if (e === "success") {
        setFile(null);
        setRealName("");
        setImageData("");
      }
    });
  };

  useEffect(() => {
    return () => {
      setRealName("");
    };
  }, []);

  const takePicture = async () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    setImageData(imageSrc!);
    const base64 = await fetch(imageSrc!);
    const blob = await base64.blob();
    const file = new File([blob], realName, {
      type: "image/png",
    });
    setFile(file);
  };

  const buttonStyle = {
    fontSize: "24px",
    height: "48px",
    fontWeight: 500,
  };

  useEffect(() => {
    if (missingDataUser?.realImage) {
      setImageData(missingDataUser.realImage);
    }
    if (missingDataUser?.realName) {
      setRealName(missingDataUser.realName);
    }
  }, [missingDataUser]);

  const resetAll = () => {
    window.location.assign(`/`);
  };

  const autoReset = useCallback(() => {
    setTimeout(() => {
      resetAll();
    }, 5000);
  }, []);

  useEffect(() => {
    if (isCompleted) {
      autoReset();
    }
  }, [autoReset, isCompleted]);

  return (
    <div className="modalContainer">
      <div
        className="clickElseWhere z1 fixCenter"
        style={{ background: "#0009" }}
        onClick={() => dispatch(setCheckInUser(null))}
      />
      <section className="sessionNumberModal py-3 z4">
        {!isNextStep ? (
          <div>
            <div
              className="mb-2 mt-1"
              style={{ fontSize: "24px", color: "#222" }}
            >
              It's your first visit! Welcome!
            </div>
            <div
              className="mb-2 mt-1"
              style={{ fontSize: "20px", color: "#222" }}
            >
              Please fill in your real name as in your ID card and take a
              picture of yourself
            </div>

            <div
              className="flex-column-ss waiverContainer"
              style={{
                maxHeight: "300px",
                textAlign: "left",
              }}
            >
              <InputField
                type="text"
                name={"Name"}
                value={realName}
                onChange={(e) => setRealName(e)}
              />
            </div>
          </div>
        ) : (
          <div>
            <div
              className="mb-2 mt-1"
              style={{ fontSize: "24px", color: "#222" }}
            >
              Please take a picture of yourself
            </div>

            <div className="flex-column-ss waiverContainer">
              {imageData ? (
                <img
                  src={imageData}
                  alt={imageData}
                  style={{ maxHeight: "60vh", alignSelf: "center" }}
                />
              ) : (
                <Webcam
                  audio={false}
                  screenshotFormat="image/jpeg"
                  ref={webcamRef}
                  width={"100%"}
                  videoConstraints={{ facingMode: "user" }}
                />
              )}
            </div>
          </div>
        )}
        {!isNextStep ? (
          <div className="flex-center full-width">
            <button
              disabled={!realName}
              className="my-1 full-width mr-2"
              onClick={() => setIsNextStep(true)}
              style={buttonStyle}
            >
              {!isNextStep ? "NEXT STEP" : "SUBMIT"}
            </button>
            <button
              style={buttonStyle}
              className="cancelButton my-1 full-width"
              onClick={() => {
                dispatch(setMissingDataUser(null));
                dispatch(setIsCompleted(true));
              }}
            >
              CANCEL
            </button>
          </div>
        ) : (
          <div className="flex-column-center full-width mt-2">
            <button
              onClick={
                imageData
                  ? () => {
                      setImageData("");
                    }
                  : takePicture
              }
              className="my-1 full-width"
              style={buttonStyle}
            >
              {imageData ? "RETAKE PICTURE" : "TAKE PICTURE"}
            </button>
            <div className="flex-center full-width">
              <button
                className="my-1 full-width cancelButton mr-2"
                onClick={() => setIsNextStep(false)}
                style={buttonStyle}
              >
                BACK
              </button>
              <button
                disabled={!imageData}
                className="my-1 full-width"
                onClick={() => handleSubmit()}
                style={buttonStyle}
              >
                {"SUBMIT"}
              </button>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

export default MissingDataModal;
