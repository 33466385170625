import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MessageModalState {
  message: string;
  isCompleted: boolean;
  isLoading: boolean;
}

const initialState: MessageModalState = {
  message: "",
  isCompleted: false,
  isLoading: false,
};

export const messageModalSlice = createSlice({
  name: "messageModal",
  initialState,
  reducers: {
    setMessageModal: (
      state: MessageModalState,
      action: PayloadAction<string>
    ) => {
      state.message = action.payload;
    },
    setIsCompleted: (
      state: MessageModalState,
      action: PayloadAction<boolean>
    ) => {
      state.isCompleted = action.payload;
    },
    setIsLoading: (
      state: MessageModalState,
      action: PayloadAction<boolean>
    ) => {
      state.isLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMessageModal, setIsCompleted, setIsLoading } =
  messageModalSlice.actions;

export default messageModalSlice.reducer;
