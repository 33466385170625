import { IonSpinner } from "@ionic/react";
import {
  getUserByEmail,
  getUserById,
  getUserByMemberId,
  getUserByPhone,
  initFirebase,
  loginStaff,
} from "app/firebase";
import { setIsCompleted, setMessageModal } from "app/slices/messageModalSlice";
import { setSession } from "app/slices/sessionSlice";
import { resetUsersState } from "app/slices/usersSlice";
import { RootState } from "app/store";
import CheckInTabs from "components/CheckInTabs";
import MessageModal from "MessageModal";
import MissingDataModal from "MissingDataModal";
import React, { useCallback, useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { useDispatch, useSelector } from "react-redux";
import SelectUserModal from "SelectUserModal";
import SessionNumberModal from "SessionNumberModal";
import WaiverModal from "WaiverModal";
import "./App.css";

export type CheckInType = "qrCode" | "email" | "memberId" | "tel";

function App() {
  const defaultLogin = { email: "", password: "" };
  const isLoading = useSelector(
    (state: RootState) => state.messageModal.isLoading
  );
  const [loginData, setLoginData] = useState(defaultLogin);
  const [checkInType, setCheckInType] = useState<CheckInType>("qrCode");
  const [phone, setPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [memberID, setMemberID] = useState("");
  const [qrData, setQrData] = useState("");

  const modalMessage = useSelector(
    (state: RootState) => state.messageModal.message
  );
  const staff = useSelector((state: RootState) => state.authUser.authUser);
  const session = useSelector((state: RootState) => state.session.session);
  const users = useSelector((state: RootState) => state.users);
  const isCompleted = useSelector(
    (state: RootState) => state.messageModal.isCompleted
  );
  const checkInUser = users.checkInUser;
  const checkInUserArr = users.checkInUserArray;
  const missingDataUser = users.missingDataUser;

  const dispatch = useDispatch();

  const handleSwitch = (type: CheckInType) => {
    setCheckInType(type);
    resetState();
  };

  const handleLogin = async () => {
    const res = await loginStaff(loginData.email, loginData.password);
    if (res !== "error") {
    } else {
      dispatch(setMessageModal("Invalid login, Please try again"));
    }
  };

  const getUserCameraAccess = async () => {
    await navigator.mediaDevices.getUserMedia({
      video: { facingMode: "environment" },
    });
  };

  const handleNextStep = async () => {
    // if (checkInType === "login") {
    //   await reAuthUser(userEmail, userPassword);
    // }
    if (checkInType === "tel") {
      await getUserByPhone(phone);
    }
    if (checkInType === "email") {
      await getUserByEmail(userEmail);
    }
    if (checkInType === "memberId") {
      await getUserByMemberId(memberID);
    }
  };

  const renderText = (type: CheckInType) => {
    if (type === "qrCode") {
      return "Please present your booking QR code";
    }
    if (type === "email") {
      return "Please type your email address";
    }
    if (type === "memberId") {
      return "Please type your 6-digit member ID";
    }
  };

  useEffect(() => {
    getUserCameraAccess();
    initFirebase();
  }, []);

  const resetState = useCallback(async () => {
    dispatch(resetUsersState());
    dispatch(setSession(null));
    setPhone("");
    setUserEmail("");
    setMemberID("");
    setQrData("");
  }, [dispatch]);

  useEffect(() => {
    if (qrData) {
      getUserById(qrData);
    }
  }, [qrData]);

  useEffect(() => {
    if (isCompleted) {
      resetState().then((e) => dispatch(setIsCompleted(false)));
      // setTimeout(() => {
      //   window.location.assign("/");
      // }, 3000);
    }
  }, [dispatch, isCompleted, resetState]);

  return (
    <div className="App borderBox">
      <header className="mb-3" style={{ fontSize: "20px" }}>
        {"Snow & Surf - Check-in Panel"}
      </header>
      {!!staff && (
        <section
          className="full-width flex-center"
          style={{ height: "48px", borderBottom: "solid #AAA 1px" }}
        >
          <CheckInTabs
            name={"QR Code"}
            type={"qrCode"}
            onClick={() => handleSwitch("qrCode")}
            currentTab={checkInType}
          />
          {/* <CheckInTabs
            name={"login"}
            type={"login"}
            onClick={() => handleSwitch("login")}
            currentTab={checkInType}
          /> */}
          <CheckInTabs
            name={"phone"}
            type={"tel"}
            onClick={() => handleSwitch("tel")}
            currentTab={checkInType}
          />
          {/* <CheckInTabs
            name={"email"}
            type={"email"}
            onClick={() => handleSwitch("email")}
            currentTab={checkInType}
          /> */}
          <CheckInTabs
            name={"membership ID"}
            type={"memberId"}
            onClick={() => handleSwitch("memberId")}
            currentTab={checkInType}
          />
        </section>
      )}
      {!!staff && <div className="mb-2 mt-3">{renderText(checkInType)}</div>}
      {!staff ? (
        <section>
          <InputField
            name={"Email"}
            value={loginData.email}
            onChange={(e) => setLoginData({ ...loginData, email: e })}
          />
          <InputField
            name={"Password"}
            type={"password"}
            value={loginData.password}
            onChange={(e) => setLoginData({ ...loginData, password: e })}
          />
          <button className="mt-4" onClick={handleLogin}>
            Staff Login
          </button>
        </section>
      ) : (
        <>
          {checkInType === "qrCode" ? (
            <div className="full-width relative">
              <QrReader
                containerStyle={{ maxHeight: "600px" }}
                onResult={(result, error) => {
                  if (!!result) {
                    //@ts-ignore
                    setQrData(result?.text);
                  }
                }}
                videoStyle={{
                  maxHeight: "70vh",
                  width: "100%",
                }}
                constraints={{ aspectRatio: 1 / 1 }}
              />
            </div>
          ) : checkInType === "tel" ? (
            <div className="full-width">
              <InputField
                type="tel"
                name={"Phone number"}
                value={phone}
                onChange={(e) => setPhone(e)}
              />
              <button
                disabled={!phone}
                className="full-width"
                onClick={handleNextStep}
              >
                NEXT
              </button>
            </div>
          ) : checkInType === "email" ? (
            <div className="full-width">
              <InputField
                type="email"
                name={"Email"}
                value={userEmail}
                onChange={(e) => setUserEmail(e)}
              />
              <button
                disabled={!userEmail}
                className="full-width"
                onClick={handleNextStep}
              >
                NEXT
              </button>
            </div>
          ) : (
            checkInType === "memberId" && (
              <div className="full-width">
                <InputField
                  name={"Member ID"}
                  value={memberID}
                  onChange={(e) => {
                    if (e.length > 10) return;
                    setMemberID(e);
                  }}
                />
                <button
                  disabled={!memberID}
                  className="full-width"
                  onClick={handleNextStep}
                >
                  NEXT
                </button>
              </div>
            )
          )}
        </>
      )}
      {/* {missingDataUser && !session && ( */}
      {missingDataUser && (
        <div className="fixCenter">
          <MissingDataModal />
        </div>
      )}
      {(checkInUser || !!checkInUserArr.length) && !session && (
        <div className="fixCenter">
          <SessionNumberModal />
        </div>
      )}
      {!!checkInUserArr.length && session && (
        <div className="fixCenter">
          <SelectUserModal />
        </div>
      )}
      {checkInUser && session && (
        <div className="fixCenter">
          <WaiverModal onClose={resetState} />
        </div>
      )}
      {modalMessage && (
        <div className="fixCenter">
          <MessageModal />
        </div>
      )}
      {isLoading && (
        <div
          className="fixCenter full-size flex-center z5"
          style={{ background: "#0008" }}
        >
          <IonSpinner
            style={{
              width: "80px",
              height: "80px",
              fill: "#FFF",
              color: "#AAA",
            }}
          />
        </div>
      )}
    </div>
  );
}

interface InputFieldProps {
  name: string;
  value: string | number;
  type?: string;
  onChange: (e: string) => void;
  style?: React.CSSProperties | undefined;
  placeholder?: string;
}

export const InputField = (props: InputFieldProps) => {
  const { type = "text" } = props;
  return (
    <div className="my-1 full-width borderBox" style={props.style}>
      <h6 style={{ fontSize: "18px", textAlign: "left" }}>{props.name}</h6>
      <input
        placeholder={props.placeholder || ""}
        className="my-1 borderBox full-width"
        type={type}
        value={type === "number" && !props.value ? undefined : props.value}
        style={{ height: "48px" }}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
      />
    </div>
  );
};

export default App;
