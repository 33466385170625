import { CheckInType } from "App";
import React from "react";

interface CheckInTabsProps {
  type: CheckInType;
  onClick: () => void;
  name: string;
  currentTab: CheckInType;
}

function CheckInTabs(props: CheckInTabsProps) {
  return (
    <div
      className={`flex1 full-size pointer flex-center ${
        props.currentTab === props.type ? "selectedTab" : "unselectedTab"
      }`}
      onClick={props.onClick}
    >
      {`By ${props.name}`}
    </div>
  );
}

export default CheckInTabs;
