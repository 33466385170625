import { Customer } from "@tsanghoilun/snow-n-surf-interface/types/user";
import { findUserInSession } from "app/firebase";
import { setIsCompleted } from "app/slices/messageModalSlice";
import { setSession } from "app/slices/sessionSlice";
import { resetUsersState, setCheckInUser } from "app/slices/usersSlice";
import { RootState } from "app/store";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Modal.css";

function SelectUserModal() {
  const [selectedUser, setSelectedUser] = useState<Customer | null>(null);
  const dispatch = useDispatch();
  const session = useSelector((state: RootState) => state.session.session);
  const checkInUserArr = useSelector(
    (state: RootState) => state.users.checkInUserArray
  );

  const handleSubmit = async () => {
    if (!selectedUser || !session) return;
    findUserInSession(session, selectedUser);
  };

  return (
    <div className="modalContainer">
      <div
        className="clickElseWhere z1 fixCenter"
        style={{ background: "#0009" }}
        onClick={() => dispatch(setCheckInUser(null))}
      />
      <section className="sessionNumberModal py-3 z4" style={{ width: "80vw" }}>
        <div className="mb-2 mt-1" style={{ fontSize: "24px", color: "#222" }}>
          Select who is checking-in
        </div>
        <div>
          {checkInUserArr.map((i) => (
            <div
              key={i.id}
              style={{
                boxShadow: "0 4px 4px #0004",
                borderRadius: "8px",
                background: selectedUser?.id === i.id ? "#ffc409" : "#FFF",
                transition: "all 0.4s",
              }}
              className="p-2 my-1 pointer relative"
              onClick={() => setSelectedUser(i)}
            >
              {!i.isSub && (
                <div
                  className="absolute p-1"
                  style={{
                    left: 16,
                    top: 8,
                    // border: "solid 1px #0004",
                    borderRadius: "4px",
                    background: "white",
                    boxShadow: "0 2px 2px #0004",
                  }}
                >
                  MAIN
                </div>
              )}
              <div>{"Username: " + i.usrname}</div>
              {i.isSub ? (
                <div>{"Account Type: Sub-account"}</div>
              ) : (
                <div>{"Account type: Main account"}</div>
              )}
              <div>{"Member Id: " + i.memberId}</div>
            </div>
          ))}
        </div>
        <div className="flex-center full-width mt-2">
          <button
            disabled={!selectedUser}
            className="my-1 full-width mr-2"
            onClick={handleSubmit}
            style={{ fontSize: "24px", height: "48px", fontWeight: 500 }}
          >
            SUBMIT
          </button>
          <button
            className="my-1 full-width cancelButton"
            onClick={() => {
              dispatch(setSession(null));
              dispatch(resetUsersState());
              dispatch(setIsCompleted(true));
            }}
            style={{ fontSize: "24px", height: "48px", fontWeight: 500 }}
          >
            CANCEL
          </button>
        </div>
      </section>
    </div>
  );
}

export default SelectUserModal;
