import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as UserTypes from "@tsanghoilun/snow-n-surf-interface/types/user";
import { RootState } from "app/store";

interface AuthUserState {
  authUser: UserTypes.Staff | null;
  isInit: boolean;
}

const initialState: AuthUserState = {
  authUser: null,
  isInit: false,
};

export const authSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    setAuthUser: (
      state: AuthUserState,
      action: PayloadAction<UserTypes.Staff | null>
    ) => {
      state.authUser = action.payload;
    },
    setAuthUserIsInit: (
      state: AuthUserState,
      action: PayloadAction<boolean>
    ) => {
      state.isInit = action.payload;
    },
  },
});

export const selectAuthUser = (state: RootState) => state.authUser.authUser;
export const selectAuthUserIsInit = (state: RootState) => state.authUser.isInit;

// Action creators are generated for each case reducer function
export const { setAuthUser, setAuthUserIsInit } = authSlice.actions;

export default authSlice.reducer;
